
// @ts-ignore
import moment from 'moment-timezone';

import { times } from 'lodash';

import { Component, Vue, Watch } from 'vue-property-decorator';

import DropdownMenu from '@/components/nav/DropdownMenu.vue';
import DropdownMenuItem from '@/components/nav/DropdownMenuItem.vue';
import Datepicker from '@/components/form/DatePicker.vue';
import Chevron from '@/components/icons/Chevron.vue';
import InputButton from '@/components/form/InputButton.vue';
import Triangle from '@/components/icons/Triangle.vue';
import MediaStats from '@/components/stats/MediaStats.vue';
import MediaStatsInfo from '@/components/stats/MediaStatsInfo.vue';

const DATE_FILTER_MAX_DAYS_PER_REQUEST = 30;

@Component({
  components: {
    DropdownMenu,
    DropdownMenuItem,
    Datepicker,
    Chevron,
    InputButton,
    Triangle,
    MediaStats,
    MediaStatsInfo,
  },
})
export default class Stats extends Vue {
  $refs!: {
    mediaStats: any;
  };

  dateFilterMenuVisible: boolean = false;
  dateFilterString: string | null = null;
  dateRangeFilter: any = {
    start: moment()
      .startOf('day')
      .add(-40, 'days')
      .toDate(),
    end: moment()
      .endOf('day')
      .toDate(),
  };
  defaultDateFilter: any = {
    start: moment()
      .startOf('day')
      .add(-40, 'days')
      .toDate(),
    end: moment()
      .endOf('day')
      .toDate(),
  };
  summarizedStats: Object = {};

  @Watch('dateRangeFilter')
  onDateRangeFilterChanged() {
    this.dateFilterMenuVisible = false;

    if (this.dateRangeFilter) {
      this.dateFilterString = this.getDateFilterDisplayString(this.dateRangeFilter);
    }
  }

  get splittedDateFilterParams() {
    if (!this.dateRangeFilter) {
      return [this.defaultDateFilter];
    }

    const diffDays = moment(this.dateRangeFilter.end).diff(this.dateRangeFilter.start, 'days') + 1;
    let dateList = [
      {
        start: this.dateRangeFilter.start.toISOString(),
        end: moment(this.dateRangeFilter.end)
          .endOf('day')
          .toISOString(),
      },
    ];
    if (diffDays > DATE_FILTER_MAX_DAYS_PER_REQUEST) {
      const numberOfSplittedDates = Math.ceil(diffDays / DATE_FILTER_MAX_DAYS_PER_REQUEST);
      const lastEndDay = diffDays % DATE_FILTER_MAX_DAYS_PER_REQUEST;
      dateList = times(numberOfSplittedDates, n => {
        return {
          start: moment(this.dateRangeFilter.start)
            .startOf('day')
            .add(DATE_FILTER_MAX_DAYS_PER_REQUEST * n, 'days')
            .toISOString(),
          end: moment(this.dateRangeFilter.start)
            .add(DATE_FILTER_MAX_DAYS_PER_REQUEST * n, 'days')
            .add(
              numberOfSplittedDates - 1 === n && lastEndDay > 0
                ? lastEndDay - 1
                : DATE_FILTER_MAX_DAYS_PER_REQUEST - 1,
              'days'
            )
            .endOf('day')
            .toISOString(),
        };
      });
    }
    return dateList;
  }

  get dateFilterParams() {
    if (this.dateRangeFilter) {
      return {
        start: this.dateRangeFilter.start.toISOString(),
        end: moment(this.dateRangeFilter.end)
          .endOf('day')
          .toISOString(),
      };
    }
    return this.defaultDateFilter;
  }

  get dateFilterStringParams() {
    return this.dateFilterString || this.defaultDateFilterString;
  }

  get defaultDateFilterString() {
    return this.getDateFilterDisplayString(this.defaultDateFilter);
  }

  getBuddhistShortYearString(year: string): string {
    let buddhistYear = (parseInt(year) + 543).toString();
    return buddhistYear.substr(buddhistYear.length - 2);
  }

  resetDateFilter() {
    this.dateRangeFilter = null;
    this.dateFilterString = null;
  }

  getDateFilterDisplayString(dateObject: any) {
    let start = moment(dateObject.start)
      .locale('th')
      .format('D MMM YYYY');
    let startYear = start.substr(start.length - 4);
    start = start.replace(startYear, this.getBuddhistShortYearString(startYear));

    let end = moment(dateObject.end)
      .locale('th')
      .format('D MMM YYYY');
    let endYear = end.substr(end.length - 4);
    end = end.replace(endYear, this.getBuddhistShortYearString(endYear));
    return `${start} - ${end}`;
  }

  downloadExcelSheet() {
    this.$refs.mediaStats.downloadExcelSheet();
  }
}
