<template>
  <Icon :src="triangleSrc" />
</template>

<script>
import Icon from '@/components/icons/Icon.vue';
import IcTriangleDn from '@/assets/icons/triangle/ic-triangle-dn.svg';

export default {
  name: 'Triangle',
  components: {
    Icon,
  },
  computed: {
    triangleSrc() {
      return IcTriangleDn;
    },
  },
};
</script>
