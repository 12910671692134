
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PlaylistMedia } from '@/types/index.d';

interface SelectProtected {
  readonly video: HTMLVideoElement;
  readonly canvas: HTMLCanvasElement;
}

/**
 * A component to show Thumbnail of a media (video, image, audio, or web).
 */
@Component
export default class ThumbnailByContent extends Vue {
  $refs!: {
    video: HTMLVideoElement;
    canvas: HTMLCanvasElement;
  };

  /**
   * A PlaylistMedia object as a property of this component to get a thumbnail.
   */
  @Prop({
    type: Object,
    default: null,
  })
  readonly value!: PlaylistMedia;

  @Prop({
    type: [Number, String],
    default: '100%',
  })
  readonly width!: object | string;

  @Prop({
    type: [Number, String],
    default: 'auto',
  })
  readonly height!: object | string;

  /**
   * Computed: get style object consisting of width and height to bind to a
   * canvas element.
   */
  get styleCanvas(): string {
    return `width: ${this.width}; height: ${this.height};`;
  }

  /**
   * Set/get whether the thumbnail is successfully loaded from the media content url.
   */
  isSuccess: boolean = false;
  /**
   * Set/get whether there is an error from the loaded image or video.
   */
  hasError: boolean = false;

  mounted() {
    const { video, canvas } = this.$refs;

    if (!video) return;

    // When the video can be played, we will seek to quater of the video if possible.
    video.oncanplay = (e: any) => {
      if (!this.isSuccess) {
        video.currentTime = Math.max(0, video.duration * 0.25);
      }
    };

    // When the video is seeked (current time is set), we can get a thumbnail of the video by
    // drawing the current frame of the video to the canvas element.
    video.ontimeupdate = (e: Event) => {
      const { clientWidth, clientHeight } = this.$el;
      const { videoWidth, videoHeight } = video;
      const ratio = videoHeight / videoWidth;
      let width = clientWidth;
      let height = width * ratio;

      if (height < clientHeight) {
        height = clientHeight;
        width = height / ratio;
      }

      (canvas as any).width = clientWidth;
      (canvas as any).height = clientHeight;
      (canvas as any).getContext('2d').drawImage(video, 0, 0, width, height);
      this.isSuccess = true;
    };
  }

  onError(e: any): void {
    this.hasError = true;
  }
}
