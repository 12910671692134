
// @ts-nocheck

import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { clone } from 'lodash';

import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import AnnouncementPreview from '@/components/announcement/Announcement.vue';

import { getAnnouncement, createAnnouncement, updateAnnouncement } from '@/services/axios';

@Component({
  components: {
    SectionHeading,
    SectionH1,
    AnnouncementPreview,
  },
})
export default class AnnouncementView extends Vue {
  @State opId!: number;

  ancmText: string = '';
  previousAncmText: string = '';
  textAreaMaxHeight: number = 64;
  isEditing: boolean = true;
  isTextLimitReached: boolean = false;
  isLoading: boolean = true;
  isSaving: boolean = false;
  isFetchingError: boolean = false;
  textLimitLength: number = 100000;
  livePreviewAncmText: string = '';
  livePreviewTimeout: any = null;
  isPreviewReady: boolean = true;
  currentAncmMsgId: string | null = null;
  popupState: string | 'saving' | 'success' | 'error' | 'none' = 'none';

  get textOverLimitMessage() {
    return `จำนวนตัวอักษรเกินกำหนดไป ${this.ancmText.length -
      this.textLimitLength} ตัว กรุณาลดจำนวนตัวอักษร`;
  }

  get highlightedAncmText() {
    if (this.isTextLimitReached) {
      let text = this.ancmText.substring(0, this.textLimitLength);
      text = `${text}<mark style="background: #f9b9c4; color: transparent;">${this.ancmText.substring(
        this.textLimitLength
      )}</mark>`;
      return text;
    }
    return `${this.ancmText}`;
  }

  get popupStateTitle() {
    if (this.popupState === 'saving') {
      return 'กำลังบันทึก';
    } else if (this.popupState === 'success') {
      return 'สำเร็จ';
    } else if (this.popupState === 'error') {
      return 'ขออภัย';
    }
    return null;
  }

  get popupStateDetail() {
    if (this.popupState === 'success') {
      return this.ancmText.trim().length ? 'ประกาศขึ้นบนจอแล้ว' : 'ลบประกาศแล้ว';
    } else if (this.popupState === 'error') {
      return 'กรุณาลองอีกครั้ง';
    }
    return null;
  }

  get popupStateIconName() {
    if (this.popupState === 'success') {
      return 'ic-complete';
    } else if (this.popupState === 'error') {
      return 'ic-fail';
    }
    return null;
  }

  @Watch('ancmText')
  onAncmTextChanged() {
    this.isTextLimitReached = this.ancmText.length > this.textLimitLength;
    this.isPreviewReady = false;
    setTimeout(() => {
      this.livePreviewAncmText = this.ancmText;
      this.isPreviewReady = true;
    }, 0);
  }

  @Watch('isEditing')
  onIsEditingChanged() {
    if (this.isEditing) {
      this.onWindowResized();
    }
  }

  onWindowResized() {
    const ref: any = this.$refs.ancmContainer;
    if (ref) {
      this.textAreaMaxHeight = ref.clientHeight - 104 - 8 - 36 - 40 - 24;
    }
  }

  onAncmTextAreaScroll(e: any) {
    const textAreaRef: any = this.$refs.ancmTextArea;
    const backdropRef: any = this.$refs.backdropAncm;
    if (textAreaRef && textAreaRef.$el && backdropRef) {
      backdropRef.scrollTop = e.target.scrollTop;
    }
  }

  handleClickClearText() {
    this.ancmText = '';
  }

  handleClickCancelEditing() {
    this.ancmText = clone(this.previousAncmText);
    this.isEditing = false;
  }

  onSaveFinished(shouldFetch: boolean) {
    setTimeout(() => {
      if (shouldFetch) {
        this.fetchAnnouncement();
      }
      this.popupState = 'none';
      this.isSaving = false;
    }, 1.5 * 1000);
  }

  handleClickSave() {
    this.isSaving = true;
    this.popupState = 'saving';

    if (this.currentAncmMsgId) {
      updateAnnouncement(this.opId, this.currentAncmMsgId, this.ancmText)
        .then(() => {
          this.popupState = 'success';
          this.onSaveFinished(true);
        })
        .catch(err => {
          this.popupState = 'error';
          this.showToastError(null, err);
          this.onSaveFinished(false);
        });
    } else {
      createAnnouncement(this.opId, this.ancmText)
        .then(() => {
          this.popupState = 'success';
          this.onSaveFinished(true);
        })
        .catch(err => {
          this.popupState = 'error';
          this.showToastError(null, err);
          this.onSaveFinished(false);
        });
    }
  }

  handleClickEditAncm() {
    this.isEditing = true;
  }

  fetchAnnouncement() {
    this.isEditing = false;

    this.isLoading = true;
    getAnnouncement(this.opId)
      .then(res => {
        const data = res.data || [];
        const messagesObj = data[0];
        if (messagesObj) {
          this.currentAncmMsgId = messagesObj.stm_id;
          const message = messagesObj.messages[0];
          if (message) {
            this.previousAncmText = message.msg;
            this.ancmText = clone(this.previousAncmText);
            this.livePreviewAncmText = this.ancmText;
          }
        }
        this.isLoading = false;
        this.isFetchingError = false;
      })
      .catch(err => {
        this.isLoading = false;
        this.isFetchingError = true;
        this.showToastError(null, err);
      });
  }

  showToastError(message: string | null, error: any = null) {
    var msg = message || 'Unexpected error occured. Please try again.';
    if (error) {
      msg = `${msg} (${error.response.error.status})`;
    }
    this.$toasted.show(msg, { type: 'error' });
  }

  mounted() {
    window.addEventListener('resize', this.onWindowResized);
    this.fetchAnnouncement();

    const ref: any = this.$refs.ancmTextArea;
    if (ref && ref.$el) {
      ref.$el.onscroll = this.onAncmTextAreaScroll;
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResized);
  }
}
