<template>
  <v-date-picker
    :mode="mode"
    :min-date="minDate"
    :max-date="maxDate"
    :select-attribute="selectAttribute"
    :value="value"
    @input="$emit('input', $event)"
    is-inline
  />
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    value: {
      type: [Date, Object],
      default: null,
    },
    mode: {
      type: String,
      default: 'range',
    },
    minDate: {
      type: Date,
      default: undefined,
    },
    maxDate: {
      type: Date,
      default: () => new Date(),
    },
  },
  computed: {
    selectAttribute: () => ({
      highlight: {
        backgroundColor: '#263373',
      },
    }),
  },
};
</script>
