<template>
  <div class="input-button" :style="{ color, backgroundColor }" @click="$emit('click')">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InputButton',
  props: {
    color: {
      type: String,
      default: '#9ca6c9',
    },
    backgroundColor: {
      type: String,
      default: '#edeff7',
    },
  },
};
</script>

<style scoped>
.input-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding-left: 8px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  min-width: 100px;
  cursor: pointer;
  user-select: none;
}
</style>
