
import { isEmpty } from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PlaylistMedia } from '@/types/index.d';
import ThumbnailByContent from '@/components/ThumbnailByContent.vue';

@Component({
  components: {
    ThumbnailByContent,
  },
})
export default class Thumbnail extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  readonly value!: PlaylistMedia;

  @Prop({
    type: [Number, String],
    default: '100%',
  })
  readonly width!: object | string;

  @Prop({
    type: [Number, String],
    default: 'auto',
  })
  readonly height!: object | string;

  @Prop({
    type: String,
    default: null,
  })
  readonly backgroundColor!: string;

  hasError: boolean = false;

  get url() {
    const thumbnailUrl = this.value.thumbnail_url;
    if (!isEmpty(thumbnailUrl)) {
      return thumbnailUrl;
    }

    const contentUrl = this.value.content_url;

    return contentUrl;
  }

  get styleWrapper() {
    return {
      display: 'flex',
      width: this.width,
      height: this.height,
      backgroundColor: this.backgroundColor,
    };
  }

  get styleImage() {
    return {
      width: this.width,
      height: this.height,
      'object-fit': 'cover',
    };
  }

  onError(e: any): void {
    this.hasError = true;
  }
}
