var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticStyle:{"height":"100%","font-weight":"400 !important"}},[_c('div',{ref:"ancmContainer",staticStyle:{"display":"flex","height":"100%","flex-direction":"column","position":"relative","background":"#f8f9fc"}},[_c('SectionHeading',{staticStyle:{"padding-bottom":"2.5rem","width":"auto","transition":"margin 1s"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('SectionH1',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._v(" ประกาศ ")]),_c('img',{staticClass:"clickable",class:_vm.isLoading || _vm.isSaving ? 'disabled' : '',staticStyle:{"width":"24px","margin-left":"8px"},attrs:{"src":require("@/assets/icons/icon-reset.svg")},on:{"click":_vm.fetchAnnouncement}})],1)]),(_vm.isLoading)?_c('div',{staticClass:"popup",staticStyle:{"position":"absolute","border-radius":"8px","background":"#f8f9fc","box-shadow":"0 0 4px 0 rgba(0, 0, 0, 0.25)","display":"flex","align-items":"center","justify-content":"center"}},[_vm._m(0)]):_vm._e(),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","flex":"1"},style:({ visibility: _vm.isLoading || _vm.isFetchingError ? 'hidden' : 'visible' })},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","flex":"1","padding":"0 16px"}},[_c('span',{staticStyle:{"font-size":"15px","font-weight":"600","color":"#263373","margin-bottom":"8px"}},[_vm._v(" ข้อความประกาศ ")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","position":"relative"},style:({
            position: _vm.isEditing ? 'relative' : 'absolute',
            visibility: _vm.isEditing ? 'visible' : 'hidden',
          })},[(_vm.popupState !== 'none')?_c('div',{staticStyle:{"position":"absolute","border-radius":"8px","background":"#f8f9fc","box-shadow":"0 0 4px 0 rgba(0, 0, 0, 0.25)","padding":"16px","display":"flex","align-items":"center","justify-content":"center","z-index":"9001","flex-direction":"column","top":"50%","left":"50%","transform":"translate(-50%, -50%)"}},[_c('span',{staticStyle:{"font-size":"14px","color":"#263373","margin-bottom":"8px"}},[_vm._v(" "+_vm._s(_vm.popupStateTitle)+" ")]),(_vm.popupStateDetail)?_c('span',{staticStyle:{"font-size":"10px","color":"#263373","margin-top":"-4px"}},[_vm._v(" "+_vm._s(_vm.popupStateDetail)+" ")]):_vm._e(),(_vm.popupStateIconName)?_c('img',{staticStyle:{"margin-top":"8px"},attrs:{"src":require(`@/assets/icons/${_vm.popupStateIconName}.svg`)}}):_c('div',{staticClass:"lds-ring-small"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._e(),_c('div',{class:{ disabled: _vm.isSaving },staticStyle:{"display":"flex","position":"relative"}},[_c('div',{ref:"backdropAncm",staticClass:"backdrop-ancm",staticStyle:{"padding":"8px","background":"white","width":"100%","overflow":"auto","min-height":"64px","border-radius":"6px"},style:({
                border: `solid 1px transparent`,
                maxHeight: `${_vm.textAreaMaxHeight}px`,
              })},[_c('span',{staticStyle:{"font-size":"14px","color":"transparent","font-weight":"500","word-break":"break-word","white-space":"pre-wrap"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightedAncmText)}})])]),_c('textarea-autosize',{ref:"ancmTextArea",staticStyle:{"border-radius":"6px","font-size":"14px","font-weight":"500","color":"#475185","padding":"8px","width":"100%","position":"absolute","top":"0","bottom":"0","left":"0","right":"0","background":"transparent"},style:({
                border: `solid 1px ${_vm.isTextLimitReached ? '#fc375f' : '#dbd1f0'}`,
              }),attrs:{"placeholder":"คุณต้องการประกาศว่าอะไร","min-height":64,"max-height":_vm.textAreaMaxHeight},model:{value:(_vm.ancmText),callback:function ($$v) {_vm.ancmText=$$v},expression:"ancmText"}})],1),(_vm.isTextLimitReached)?_c('span',{staticClass:"text-red",staticStyle:{"font-size":"12px","margin-top":"2px"}},[_vm._v(_vm._s(_vm.textOverLimitMessage))]):_vm._e(),_c('div',{class:{ disabled: _vm.isSaving },staticStyle:{"display":"flex","flex-direction":"row","align-items":"center","justify-content":"space-between","margin-top":"36px"},style:({
              marginTop: _vm.isTextLimitReached ? '20px' : '36px',
            })},[_c('div',{staticClass:"underline-button",on:{"click":_vm.handleClickClearText}},[_c('span',[_vm._v("ล้างข้อความ")])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('div',{staticClass:"underline-button",on:{"click":_vm.handleClickCancelEditing}},[_c('span',[_vm._v("ยกเลิก")])]),_c('div',{staticClass:"save-button",class:{ disabled: _vm.isTextLimitReached },staticStyle:{"margin-left":"16px"},on:{"click":_vm.handleClickSave}},[_vm._v(" บันทึก ")])])])]),(!_vm.previousAncmText.length && !_vm.isEditing)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_vm._m(1),_c('div',{staticClass:"save-button",staticStyle:{"margin-top":"36px","width":"128px"},on:{"click":_vm.handleClickEditAncm}},[_vm._v(" เขียนประกาศ ")])]):(!_vm.isEditing)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"display":"flex","border-radius":"6px","border":"solid 1px #dbd1f0","font-size":"14px","color":"#9ca6c9","overflow":"auto","min-height":"64px","padding":"8px","margin-bottom":"36px","word-break":"break-word","white-space":"pre-wrap"},style:({
              maxHeight: '80%',
            })},[_c('span',[_vm._v(_vm._s(_vm.ancmText))])]),_c('div',{staticClass:"save-button",staticStyle:{"width":"128px"},on:{"click":_vm.handleClickEditAncm}},[_vm._v("แก้ไข")])]):_vm._e()]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","width":"432px","padding":"0 16px","flex-shrink":"0"}},[_c('span',{staticStyle:{"font-size":"15px","font-weight":"600","color":"#263373","margin-bottom":"8px"}},[_vm._v(" ตัวอย่าง ")]),_c('div',{staticStyle:{"display":"flex","position":"relative","border-radius":"4px","background":"black","height":"225px","width":"100%"}},[(_vm.isPreviewReady)?_c('AnnouncementPreview',{staticStyle:{"position":"absolute","bottom":"0","left":"0","right":"0"},attrs:{"text":_vm.livePreviewAncmText}}):_vm._e()],1)])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"lds-ring",staticStyle:{"transform":"scale(0.3)"}},[_c('div'),_c('div'),_c('div'),_c('div')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","border-radius":"6px","border":"solid 1px #dbd1f0","font-size":"14px","color":"#9ca6c9","height":"40px"}},[_c('span',[_vm._v("ยังไม่มีประกาศ")])])
}]

export { render, staticRenderFns }