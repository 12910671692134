
// @ts-ignore
import _ from 'lodash-es';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Mutation, Action } from 'vuex-class';

@Component({
  components: {},
})
export default class MediaStatsInfo extends Vue {}
