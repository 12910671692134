<template>
  <div
    style="
      display: flex;
      align-items: center;
      height: 30px;
      max-height: 300px;
      background: #001180;
      border-top: solid 1px rgba(255, 255, 255, 0.35);
      margin-top: -30px;
      z-index: 10;
    "
  >
    <div
      class="announcement"
      ref="announcement"
      :style="{
        justifyContent: isOverflowing ? 'flex-start' : 'center',
      }"
    >
      <div v-if="text && !isMarqueeEnabled" style="padding: 0 8px">
        {{ text }}
      </div>
      <div v-else-if="text && isMarqueeEnabled" class="marquee">
        <div
          class="marquee-div marquee-animate"
          ref="marqueeDiv"
          v-on:animationend="onMarqueeEnded"
        >
          <span class="marquee-span" style="padding: 0 8px">
            {{ text }}
          </span>
          <span class="marquee-span">
            {{ text }}
          </span>
        </div>
      </div>
      <div v-else style="display: flex; align-items: center; justify-content: center; flex: 1">
        <span>ประกาศของคุณจะแสดงที่นี่</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isMarqueeEnabled: false,
      isMarqueeProcessing: false,
      isOverflowing: false,
    };
  },
  watch: {
    text() {
      const marqueeDivRef = this.$refs.marqueeDiv;

      if (marqueeDivRef) {
        marqueeDivRef.classList.remove('marquee-animate');
      }
      this.isMarqueeEnabled = false;
      this.startMarqueeIfNeeded(true);
    },
  },
  methods: {
    isOverflown(ref) {
      if (ref) {
        return ref.scrollWidth > ref.clientWidth;
      }
      return false;
    },
    onMarqueeEnded() {
      this.isMarqueeEnabled = false;
      setTimeout(() => {
        this.startMarqueeIfNeeded();
      }, 0);
    },
    startMarqueeIfNeeded(force) {
      if (this.isMarqueeProcessing && !force) {
        return;
      }
      this.isOverflowing = this.isOverflown(this.$refs.announcement);
      const textRef = this.$refs.announcement;

      if (this.isOverflowing) {
        this.isMarqueeProcessing = true;

        const duration = Math.ceil(textRef.scrollWidth / textRef.clientWidth) * 6;

        setTimeout(() => {
          this.isMarqueeEnabled = true;
          this.isMarqueeProcessing = false;

          this.$nextTick(() => {
            const marqueeDivRef = this.$refs.marqueeDiv;

            if (marqueeDivRef && textRef) {
              marqueeDivRef.classList.remove('marquee-animate');
              marqueeDivRef.style.animationDuration = `${duration}s`;
              marqueeDivRef.classList.add('marquee-animate');
            }
          });
        }, 2000);
      } else {
        setTimeout(() => {
          this.startMarqueeIfNeeded();
        }, 7000);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.startMarqueeIfNeeded();
    }, 0);
  },
};
</script>

<style scoped>
.announcement {
  color: white;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}

.marquee {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee-div {
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.marquee-animate {
  -webkit-animation: scroll-left 45s linear;
  animation: scroll-left 45s linear;
}

.marquee-span {
  float: left;
  width: 50%;
  margin-right: 30vw;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(0%);
  }
  100% {
    -moz-transform: translateX(-50%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-50%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
</style>
