
// @ts-ignore
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment-timezone';
import TimeFormat from 'hh-mm-ss';
import { forEach, sumBy, flattenDeep, map, find, groupBy, cloneDeep, flatten } from 'lodash';

import get from 'lodash/get';
import values from 'lodash/values';

import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State, Mutation, Action } from 'vuex-class';
import { Media, MediaStat } from '@/types/index.d';
import { getMediaStats, getWebStats } from '@/services/axios';
import { getMediaType } from '@/services/media';
import Chevron from '@/components/icons/Chevron.vue';
import Thumbnail from '@/components/Thumbnail.vue';

@Component({
  components: {
    Chevron,
    Thumbnail,
  },
})
export default class MediaStats extends Vue {
  @State opId!: number;
  @State allMedia!: Media[];
  @Prop() readonly dateFilterParams!: any;
  @Prop() readonly dateFilterStringParams!: string;
  @Prop() readonly splittedDateFilterParams!: any;

  stats: any[] | null = null;
  pageSize: number = 10;
  currentPage: number = 0;
  currentChunkRequestIndex: number = 0;
  tempResponseStats: any[] = [];
  isLoading: boolean = true;

  @Watch('splittedDateFilterParams')
  onSplittedDateFilterParamsChanged() {
    this.loadStats();
  }

  get summarizedStats() {
    let summarizedStats = values(
      map(groupBy(this.stats, 'm_id'), (stats: any) => ({
        op_id: stats[0].op_id,
        m_id: stats[0].m_id,
        url: stats[0].url,
        media: this.getMedia(stats[0].m_id),
        played_count: sumBy(stats, 'played_count'),
      }))
    );
    return summarizedStats;
  }

  get paginatedList() {
    if (this.summarizedStats) {
      return this.summarizedStats.slice(this.currentPage, this.currentPage + this.pageSize);
    }
    return null;
  }
  get isFirstPage() {
    return this.currentPage === 0;
  }

  get isLastPage() {
    if (this.summarizedStats !== null) {
      return this.currentPage + this.pageSize >= this.summarizedStats.length;
    }
    return true;
  }

  @Watch('summarizedStats', { immediate: true, deep: true })
  onSummarizedStatsChanged() {
    this.$emit('input', this.summarizedStats);
  }

  mounted() {
    this.loadStats();
  }

  loadStats() {
    if (this.currentPage != 0) {
      this.currentPage = 0;
    }

    this.isLoading = true;

    const currentDateFilterParams = this.splittedDateFilterParams[this.currentChunkRequestIndex];

    Promise.all([
      getMediaStats(this.opId, currentDateFilterParams.start, currentDateFilterParams.end),
      getWebStats(this.opId, currentDateFilterParams.start, currentDateFilterParams.end),
    ])
      .then(values => {
        this.tempResponseStats.push(
          flattenDeep(
            map(values, (res: any) => {
              return res.data;
            })
          )
        );
        this.currentChunkRequestIndex += 1;
        if (this.currentChunkRequestIndex >= this.splittedDateFilterParams.length) {
          this.stats = cloneDeep(flatten(this.tempResponseStats));
          this.currentChunkRequestIndex = 0;
          this.tempResponseStats = [];
          this.isLoading = false;
        } else {
          setTimeout(() => {
            this.loadStats();
          }, 3000);
        }
      })
      .catch(err => {
        this.retryLoadStats();
      });
  }

  retryLoadStats() {
    this.currentChunkRequestIndex = 0;
    this.tempResponseStats = [];
    setTimeout(() => {
      this.loadStats();
    }, 3000);
  }

  getMedia(mId: number): Media | null {
    return (
      find(this.allMedia, (media: any) => {
        return media.m_id === mId;
      }) || null
    );
  }

  getMediaDisplayName(stat: any): string {
    if (stat.media) {
      return get(stat, 'media.name', '');
    } else {
      return get(stat, 'url', '');
    }
  }

  getMediaType(stat: any): string {
    if (stat.url) {
      return 'เว็บไซต์';
    }
    return getMediaType(get(stat, 'media.typ_id', 0));
  }

  getMediaDuration(stat: any): string {
    const length = get(stat, 'media.length', '');
    if (length) {
      return TimeFormat.fromS(Math.round(length), 'mm:ss');
    }
    return '-';
  }

  addCurrentPage(pages: number) {
    this.currentPage += pages;
    if (this.currentPage < 0) this.currentPage = 0;
    if (this.currentPage > this.summarizedStats.length) {
      this.currentPage = this.summarizedStats.length - this.pageSize;
    }
  }

  async downloadExcelSheet() {
    const { start, end } = this.dateFilterParams;
    const { dateFilterStringParams } = this;
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet();
    // ws.columns = [
    //   { key: 'name', width: 30, outlineLevel: 1, hidden: false, style:  },
    //   { key: 'type', width: 10, outlineLevel: 1, hidden: false, style:  },
    //   { key: 'duration', width: 22, outlineLevel: 1, hidden: false, style:  },
    //   { key: 'platedCount', width: 22, outlineLevel: 1, hidden: false, style:  },
    // ];

    ws.addRow(['Date', `${dateFilterStringParams}`], '');
    ws.addRow(['สื่อ', 'ประเภท', 'ระยะเวลาสื่อ (วินาที)', 'จำนวนครั้งที่แสดง (ครั้ง)'], 'b');

    forEach(this.summarizedStats, (o: any) => {
      ws.addRow(
        [
          this.getMediaDisplayName(o),
          this.getMediaType(o),
          this.getMediaDuration(o),
          o.played_count,
        ],
        ''
      );
    });

    const buffer = await wb.xlsx.writeBuffer();
    const filename = `${moment(start).format('YYYYMMDD')}-${moment(end).format(
      'YYYYMMDD'
    )}stats-export.xlsx`;

    FileSaver.saveAs(new Blob([buffer]), filename);
  }
}
