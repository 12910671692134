import { PlaylistMedia } from '@/types/index.d';
import TimeFormat from 'hh-mm-ss';

/* eslint-disable import/prefer-default-export */
export function getMediaType(typ_id: number): string {
  if (typ_id === 0) return 'ทั้งหมด';
  if (typ_id === 1) return 'วิดีโอ';
  if (typ_id === 2) return 'เสียง';
  if (typ_id === 3) return 'ภาพ';
  if (typ_id === 4) return 'เว็บไซต์';
  return `ไม่ทราบประเภท (${typ_id})`;
}

/**
 * Get duration in format of `mm:ss` of a media in the playlist.
 * @param duration a playlist media duration in second unit.
 * @returns duration of a media in format of `mm:ss`, or `-` if no duration.
 */
export function getPlaylistMediaDuration(duration: number | null): string {
  if (duration) {
    return TimeFormat.fromS(Math.round(duration), 'mm:ss');
  }
  return '-';
}
